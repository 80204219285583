import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import BlogBink, { BlogBinkPosts } from '@ubo/blog-bink'

// Layout
import GridPrimary from 'components/flex/Posts/Layout/GridPrimary'

// Interface
import { LatestPostsProps } from 'components/flex/Posts/Latest/Shell'

const Section = styled.section`
  background-color: #f8f8f8;
`

const LatestPosts: React.FC<LatestPostsProps> = ({ fields }) => {
  const {
    allWpPost,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.latestPostsQueryQuery>(graphql`
    query latestPostsQuery {
      allWpPost(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
    }
  `)

  const posts: any = allWpPost.edges

  return (
    <Section className="py-5">
      <BlogBink
        posts={posts as BlogBinkPosts}
        id="latest-posts"
        limit={Number(fields.limit)}
      >
        <div className="container pb-5">
          {/* @ts-ignore */}
          <GridPrimary posts={posts} fields={fields} />
        </div>
      </BlogBink>
    </Section>
  )
}

export default LatestPosts
