import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

// Images
import Arrow from 'img/arrow.inline.svg'
import NavLink from 'components/shared/NavLink'

const Anchor = styled(motion.span)<{ type: string }>`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  ${(props) =>
    props.type === 'contrast'
      ? css`
          color: ${({ theme }) => theme.color.primary};

          & svg path {
            fill: ${({ theme }) => theme.color.primary};
          }
        `
      : css`
          color: ${({ theme }) => theme.color.light};
        `}
`

interface AnchorPrimaryProps {
  to: string
  className?: string
  target?: string
  isLight?: boolean
  type?: string
}

const AnchorPrimary: React.FC<AnchorPrimaryProps> = ({
  children,
  to,
  className = '',
  target,
  isLight,
  type,
}) => {
  const [hovered, setHovered] = useState<boolean>(false)

  return (
    <NavLink to={to}>
      <Anchor
        className={className}
        target={target}
        onHoverStart={() => setHovered(true)}
        onHoverEnd={() => setHovered(false)}
        type={type}
      >
        {children}
        <motion.span animate={hovered ? { x: 7 } : { x: 0 }} isLight={isLight}>
          <Arrow className="ms-2 ms-sm-3" />
        </motion.span>
      </Anchor>
    </NavLink>
  )
}

export default AnchorPrimary
