import React from 'react'

// Components
import LatestPosts from 'components/flex/Posts/Latest/LatestPosts'

export interface LatestPostsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_LatestPostTypes
  location?: any
  post?: any
  node?: {
    id: string
    title: string
    uri: string
    recap: {
      excerpt: string
    }
  }
}

interface PostProps {
  [key: string]: any
}

const Shell: React.FC<LatestPostsProps> = ({ fields, location = {} }) => {
  const types: PostProps = {
    blog: LatestPosts,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default Shell
